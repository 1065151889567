<ba-header-nav></ba-header-nav>

<div class="wrapper">
    <router-outlet></router-outlet>
</div>

<!--<ba-scroll-top></ba-scroll-top>-->
<div *ngIf="isBrowser">
  <ba-policy-popup></ba-policy-popup>
</div>
