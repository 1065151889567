import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreakpointsService {

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
  }

  public watchBreakpoints(breakpoint: number): Observable<boolean> {
    return this.breakpointObserver.observe([`(max-width: ${breakpoint}px)`])
      .pipe(map((state: BreakpointState) => state.matches));
  }
}
