import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { fadeAnimation, fadeDownAnimation, slideAnimation } from './animations/animations';

import { MenuItem, MenuItems } from './header-nav.model';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { BreakpointsService } from '../../services/breakpoints.service';

@Component({
  selector: 'ba-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
  animations: [fadeAnimation, fadeDownAnimation, slideAnimation]
})
export class HeaderNavComponent implements OnInit {
  public menuItems = MenuItems;
  public currentMenuItem: MenuItem = null;
  public extendedOpen = false;
  public isMobile = false;
  public selectedMenuItemIndex: number = null;

  private currentUrl = null;
  private scrolled = false;
  private shouldBeInverted = false;
  private prevSelectedMenuItemIndex: number = null;
  private destroy = new Subject<void>();
  private openExtendTimeout: NodeJS.Timeout = null;

  // private timeoutToClose = null;

  constructor(
    private router: Router,
    private breakpointsService: BreakpointsService,
    @Inject(DOCUMENT) private dom: Document
  ) {
  }

  ngOnInit(): void {
    this.watchBreakpoints();
    this.getRouterData();
  }

  public get invert(): boolean {
    return !this.scrolled && this.shouldBeInverted;
  }

  @HostListener('window:resize', [])
  @HostListener('window:scroll', [])
  private onWindowScroll(): void {
    this.scrolled = window.innerHeight - (window.pageYOffset + 50) < 0;
  }

  @HostListener('document:keydown.escape', ['$event'])
  private onKeydownHandler(event: KeyboardEvent): void {
    if (this.extendedOpen) {
      this.handleCloseExtended();
    }
  }

  /**
   * Watch media queries for redraw menu
   */
  private watchBreakpoints(): void {
    this.breakpointsService.watchBreakpoints(991)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: isBreakpointAchieved => {
          this.isMobile = isBreakpointAchieved;
          if (!isBreakpointAchieved) {
            this.handleCloseExtended();
          }
        }
      });
  }

  private getRouterData(): void {
    this.router.events
      .pipe(takeUntil(this.destroy), filter(e => e instanceof NavigationEnd))
      .subscribe(data => {
        this.currentUrl = data['url'];
        this.shouldBeInverted = data['url'] === '/';
      });
  }

  public handleNavigate(route: string[]): void {
    this.router.navigate(route);
    this.handleCloseExtended();
  }

  public onMouseHover(menuItem: MenuItem, index: number): void {
    if (this.menuItems.length !== index + 1 && menuItem.submenu) {
      this.currentMenuItem = menuItem;
      if (!this.extendedOpen) {
        this.openExtendTimeout = setTimeout(() => this.openExtended(), 500);
      }
      this.prevSelectedMenuItemIndex = this.selectedMenuItemIndex;
      this.selectedMenuItemIndex = index;
    } else {
      this.handleCloseExtended();
    }
  }

  private openExtended(): void {
    this.dom.body.style.overflowY = 'hidden';
    this.extendedOpen = true;
  }

  public handleCloseExtended(): void {
    this.dom.body.style.overflowY = null;
    this.extendedOpen = false;
    if (this.prevSelectedMenuItemIndex || this.selectedMenuItemIndex) {
      this.prevSelectedMenuItemIndex = this.selectedMenuItemIndex = null;
    }

    if (this.isMobile) {
      this.menuItems.forEach(el => {
        if (el.expanded) {
          el.expanded = false;
        }
      });
    }
  }

  public handleShowLinklist(index: number): void {
    if (!this.menuItems[index].submenu) {
      this.router.navigateByUrl(this.menuItems[index].path);
      this.menuItems.forEach(item => item.expanded = false);
      this.handleCloseExtended();
      return;
    }

    this.menuItems[index].expanded = !this.menuItems[index].expanded;
  }

  public toggleMobileMenu(): void {
    if (!this.extendedOpen) {
      this.openExtended();
    } else {
      this.handleCloseExtended();
    }
  }

  public handleCheckCurrentUrl(path: string): boolean {
    return Boolean(this.currentUrl.match(`${path}(?!-)`));
  }

  public clearOpenExtendTimeout(): void {
    clearTimeout(this.openExtendTimeout);
  }

  /**
   * If in the future we will need to close the navigation bar after a specified period of time.
   */
  // public handleOverlayEvent(hovered: boolean): void {
  //   hovered
  //     ? (this.timeoutToClose = setTimeout(() => this.handleCloseExtended(), 2000))
  //     : clearTimeout(this.timeoutToClose);
  // }
}
