<div class="popup-policy" id="popup-policy" [hidden]="isSelected">
  <div class="container">
    <p class="popup-policy__text" *ngIf="isMobile; else webVersion">
      Our website uses <a routerLink="privacy"> cookies</a> to give you a better user experience.
    </p>
    <ng-template #webVersion>
      <p class="popup-policy__text">
        This website uses cookies. By clicking 'I Agree' or by continuing to browse this site, you are giving your consent
        for cookies to be used. For more information on cookies and how you can disable them, kindly visit our
        <a routerLink="privacy"> Privacy Policy.</a>
      </p>
    </ng-template>
    <div>
      <button class="popup-policy__btn popup-policy__btn--agree" (click)="setPolicyStatus(true)">I agree</button>
      <button class="popup-policy__btn popup-policy__btn--decline" (click)="setPolicyStatus(false)">Decline</button>
    </div>
  </div>
</div>
