import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from './pages/pages.module';
import { SkeletonModule } from './components/skeleton.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { HeaderNavComponent } from './components/header-nav/header-nav.component';
import { PolicyPopupComponent } from './components/policy-popup/policy-popup.component';
import { translateBrowserLoaderFactory } from './loaders/translate-browser.loader';


@NgModule({
  declarations: [
    AppComponent,
    HeaderNavComponent,
    PolicyPopupComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'baApp'}),
    BrowserAnimationsModule,
    HttpClientModule,
    TransferHttpCacheModule,
    PagesModule,
    AppRoutingModule,
    CookieModule.forRoot(),
    SkeletonModule,
    HammerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    NgxGoogleAnalyticsModule.forRoot('UA-2882564-62'),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
