export interface MenuItem {
  title: string;
  path?: string;
  expanded?: boolean;
  submenu?: SubMenu[];
  preview?: any;
  externalUrl?: string;
  externalUrlBlank?: string;
}

export interface SubMenu {
  title: string;
  path?: string;
  menuLinks?: MenuItem[];
}

export const MenuItems: MenuItem[] = [
  {
    title: 'Our Services',
    path: 'ourservices',
    expanded: false,
    submenu: [
      {
        title: 'Our Services',
        path: 'ourservices',
        menuLinks: [
          { title: 'Custom software development', path: 'custom' },
          { title: 'Backend development and custom integrations', path: 'backend' },
          { title: 'Web application development', path: 'web' },
          { title: 'Mobile application development', path: 'mobile' },
          { title: 'MVP development', path: 'mvp-development' },
          { title: 'Application modernization', path: 'application-modernization' },
          { title: 'Software project rescue', path: 'software-project-rescue' },
          { title: 'Performance analysis', path: 'performance' },
          { title: 'Maintenance and support', path: 'maintenance-support' },
          { title: 'Telecom systems integration', path: 'telecom-systems-integration' }
        ]
      }
    ]
  },
  {
    title: 'Industries',
    path: 'industries',
    expanded: false,
    submenu: [
      {
        title: 'Industries',
        path: 'industries',
        menuLinks: [
          { title: 'Healthcare', path: 'healthcare' },
          { title: 'Education', path: 'education' },
          { title: 'GIS', path: 'gis' },
          { title: 'e-Commerce', path: 'ecommerce' },
          { title: 'Security systems', path: 'secure' },
          { title: 'Telecom', path: 'telecom' },
          { title: 'Finance', path: 'fintech' },
          { title: 'Marketing & ad', path: 'martech-adtech' },
          { title: 'Agriculture', path: 'agriculture' }
        ]
      },
      {
        title: 'Solutions',
        path: 'solutions',
        menuLinks: [
          { title: 'LMS', path: 'LMS' },
          { title: 'ERP', path: 'ERP' },
          { title: 'CRM', path: 'CRM' },
          { title: 'CMS', path: 'CMS' },
          { title: 'Big data', path: 'big-data' },
          { title: 'Shopify store', path: 'shopify-store' },
          { title: 'Live streaming', path: 'live-streaming' },
          { title: 'Real-time communication', path: 'real-time-communication' }
        ]
      },
    ]
  },

  {
    title: 'Technologies',
    path: 'technologies',
    expanded: false,
    submenu: [
      {
        title: 'Technologies',
        path: 'technologies',
        menuLinks: [
          { title: 'Node.js', path: 'nodejs' },
          { title: 'Java', path: 'java'},
          { title: '.NET', path: 'NET' },
          { title: 'C++', path: 'c-plus-plus' },
          { title: 'Frontend', path: 'frontend' },
          { title: 'Angular', path: 'angular' },
          { title: 'React', path: 'react' },
          { title: 'Vue.js', path: 'vue' },
          { title: 'iOS', path: 'ios' },
          { title: 'Android', path: 'android' },
          { title: 'Asterisk', path: 'asterisk' }
        ]
      }
    ]
  },
  {
    title: 'Case Studies',
    path: 'case-studies',
    expanded: false,
    submenu: [
      {
        title: 'Case Studies',
        path: '',
        menuLinks: [
          { title: 'Case studies', path: 'case-studies' },
          { title: 'Portfolio', externalUrlBlank: 'https://design.bambooagile.eu/' }
        ]
      }
    ],
    preview: {
      bg: 'assets/images/header-nav/case-studies.jpg',
      link: '/case-studies/beed-lms',
      external: false,
      info: {
        header: 'Featured case study',
        title: 'BeED',
        description: 'An extensive learning management system created to transform conventional learning and teaching into great interaction between institutions, educators, parents and learners.'
      }
    }
  },
  {
    title: 'Company',
    path: 'about-us',
    expanded: false,
    submenu: [
      {
        title: 'Company',
        path: '',
        menuLinks: [
          { title: 'About us', path: 'about-us' },
          { title: 'Insights', externalUrl: 'https://bambooagile.eu/insights/' }
        ]
      }
    ],
    preview: {
      bg: 'assets/images/header-nav/company.jpg',
      link: 'https://bambooagile.eu/insights/elearning-gamification-5-examples/',
      external: true,
      info: {
        header: 'Featured article',
        title: 'What is eLearning Gamification?',
        description: 'How applicable is gamification to one of the fastest-growing software niches of today? Can it change the whole field of education for the better? Find the answers in our recent article.'
      }
    }
  },
  { title: 'Estimate Project', path: 'estimate' },
  { title: 'Contact Us', path: 'contact-us' },
];
