import { Inject, Injectable } from '@angular/core';
import supportsWebP from 'supports-webp';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  public isWebpSupportEnabled = true;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  /**
   * Check browser support for the .webp extension
   */
  public isSupportWebP(): Observable<boolean> {
    return from(supportsWebP).pipe(tap((isWebpSupportEnabled) => this.isWebpSupportEnabled = isWebpSupportEnabled));
  }

  /**
   * Replace image extension from .webp to other if browser doesn't support this format
   */
  public replaceImage(image: string, extension = '.jpg'): string {
    return this.isWebpSupportEnabled
      ? image
      : image.replace('.webp', extension);
  }

  /**
   * Set image path to href of preload link
   * Or create preload link for image if not exist
   */
  public preloadImage(image: string, path: string): void {
    const imagePath = path + image;
    const preloadImageLink = this.document.getElementById('image-preload');
    if (!preloadImageLink) {
      const link = document.createElement('link');
      link.setAttribute('rel', 'preload');
      link.setAttribute('href', imagePath);
      link.setAttribute('as', 'image');
      link.setAttribute('id', 'image-preload');
      document.head.insertAdjacentElement('afterbegin', link);
    } else {
      preloadImageLink.setAttribute('href', imagePath);
    }
  }

  /**
   * Update preload link and return current image with extension
   */
  public setPreloadImage(image: string, path: string): string {
    const updatedImage = this.replaceImage(image);
    this.preloadImage(updatedImage, path);

    return updatedImage;
  }

  /**
   * Remove image preload link from DOM
   */
  public destroyPreloadImage(): void {
    const preloadImageLink = this.document.getElementById('image-preload');
    if (preloadImageLink) {
      preloadImageLink.parentNode.removeChild(preloadImageLink);
    }
  }
}
