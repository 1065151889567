import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { environmentLoader } from './environments/environmentLoader';
import 'hammerjs';

document.addEventListener('DOMContentLoaded', () => {
  environmentLoader.then(env => {
    if (env.production) {
      enableProdMode();
    }
    environment.settings = env.settings;
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => console.log(err));
  });
});

