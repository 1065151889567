import { trigger, state, style, transition, animate } from '@angular/animations';

const cubicFunction = ' cubic-bezier(0.55, 0.31, 0.15, 0.93)';

export const fadeAnimation = trigger('fade', [
  state(
    'void',
    style({
      opacity: 0
    })
  ),
  state(
    'showing',
    style({
      opacity: 1
    })
  ),
  transition('void => *', animate('300ms' + cubicFunction)),
  transition('* => void', animate('300ms' + cubicFunction, style({ opacity: 0 })))
]);

export const fadeDownAnimation = trigger('fadeDown', [
  state(
    'void',
    style({
      opacity: 0,
      transform: 'translateY(-50px)'
    })
  ),
  state(
    '*',
    style({
      opacity: 1,
      transform: 'translateY(0)'
    })
  ),
  transition('void => *', animate('300ms' + cubicFunction)),
  transition('* => void', animate('200ms' + cubicFunction))
]);

const defaultStyle = {
  transform: 'translateX(0)',
  opacity: 1
};

const nextState = {
  transform: 'translateX(-300px)',
  opacity: 0
};


export const slideAnimation = trigger('slideAnimation', [
  transition(':increment', [style(defaultStyle),
    animate(`300ms ${cubicFunction}`, style(nextState)),
    animate(`300ms ${cubicFunction}`, style(defaultStyle)),
  ]),
  transition(':decrement', [style(defaultStyle),
    animate(`300ms ${cubicFunction}`, style(nextState)),
    animate(`300ms ${cubicFunction}`, style(defaultStyle)),
  ]),
]);
