import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ApiService } from '../../services/api.service';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreakpointsService } from '../../services/breakpoints.service';

@Component({
  selector: 'ba-policy-popup',
  templateUrl: './policy-popup.component.html',
  styleUrls: ['./policy-popup.component.scss']
})
export class PolicyPopupComponent implements OnInit, OnDestroy {
  public isSelected = false;
  public isMobile = false;
  private destroy = new Subject<void>();

  constructor(
    private cookie: CookieService,
    private api: ApiService,
    private breakpointsService: BreakpointsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  ngOnInit(): void {
    this.watchBreakpoint();
    this.updateCookies();

    if (isPlatformBrowser(this.platformId)) {
      if (this.cookie.get('allowed')) {
        this.isSelected = true;
      }
    }
  }

  private watchBreakpoint(): void {
    this.breakpointsService.watchBreakpoints(767)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: isBreakpointAchieved => this.isMobile = isBreakpointAchieved
      });
  }

  private updateCookies(): void {
    this.api.logCookie().pipe(takeUntil(this.destroy))
      .subscribe(({ cookie }) => {
        this.cookie.put('gdpr', cookie.value, {
          expires: new Date(cookie.expires),
          secure: !cookie.http
        });
      });
  }

  public setPolicyStatus(status: boolean): void {
    status ? this.cookie.put('allowed', 'true') : this.cookie.put('allowed', 'false');
    this.isSelected = true;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
