import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ImageService } from './services/image.service';

@Component({
  selector: 'ba-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isBrowser: boolean;
  private destroy = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private imageService: ImageService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.scrollTop();
    this.imageService.isSupportWebP()
      .pipe(takeUntil(this.destroy))
      .subscribe();
  }

  /**
   * Scroll top after redirection to other route
   */
  private scrollTop(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy),
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe({
        next: () => setTimeout(() => this.viewportScroller.scrollToPosition([0, 0]))
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
