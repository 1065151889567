import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { PagesRoutingModule } from './pages-routing.module';


@NgModule({
  imports: [
    CommonModule,
    NgxSliderModule,
    NgxSmartModalModule.forRoot(),
    PagesRoutingModule,
  ],
  declarations: []
})
export class PagesModule {
}
