/**
 * Default project settings
 * For now all settings takes from other file
 * Path to file: /assets/environments/environments.json
 *
 * environments.json - file where we can change builds
 */
export const environment = {
  production: false,
  settings: {
    backend: 'http://localhost:8073',
    isActiveGoogleAnalytics: false
  }
};

// Replace object at /assets/environments/environments.json
/**
 * environments.json
 * PRODUCTION MODE
 * {
 *    production: true
 *    settings: { backend: bambooagile.eu }
 * }
 */

/**
 * environments.json
 * TEST MODE
 * {
 *    production: true
 *    settings: { backend: agile.bam-boo.eu }
 * }
 */
