import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';

import { TitleBarComponent } from './title-bar/title-bar.component';
import { ContactEstimateFormComponent } from './contact-estimate-form/contact-estimate-form.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { EstimateFormComponent } from './estimate-form/estimate-form.component';
import { FooterComponent } from './footer/footer.component';
import { ContactsComponent } from './contacts/contacts.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { SocialsComponent } from './socials/socials.component';
import { BannerSectionComponent } from './banner-section/banner-section.component';
import { PageBlockComponent } from './page-block/page-block.component';
import { TranslateModule } from '@ngx-translate/core';
import { FaqBlockComponent } from './faq-block/faq-block.component';
import { FaqPointComponent } from './faq-block/faq-point/faq-point.component';
import { DirectivesModule } from '../directives/directives.module';
import { StartBlockComponent } from './start-block/start-block.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCeRqXCS15iX9ENbr2cJ7No5ndnzHzmWbY'
    }),
    TranslateModule,
    DirectivesModule
  ],
  declarations: [
    TitleBarComponent,
    ContactEstimateFormComponent,
    ContactFormComponent,
    EstimateFormComponent,
    FooterComponent,
    ContactsComponent,
    BreadcrumbsComponent,
    SocialsComponent,
    BannerSectionComponent,
    PageBlockComponent,
    StartBlockComponent,
    FaqBlockComponent,
    FaqPointComponent
  ],
  exports: [
    TitleBarComponent,
    ContactEstimateFormComponent,
    ContactFormComponent,
    EstimateFormComponent,
    FooterComponent,
    ContactsComponent,
    SocialsComponent,
    BannerSectionComponent,
    PageBlockComponent,
    StartBlockComponent,
    FaqBlockComponent
  ]
})
export class SkeletonModule {
}
