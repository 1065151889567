import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'about-us', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'privacy', loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'contact-us', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: 'estimate', loadChildren: () => import('./estimator/estimator.module').then(m => m.EstimatorModule) },
  { path: 'case-studies', loadChildren: () => import('./case-studies/case-studies.module').then(m => m.CaseStudiesModule) },
  { path: 'technologies', loadChildren: () => import('./technologies/technologies.module').then(m => m.TechnologiessModule) },
  { path: 'solutions', loadChildren: () => import('./solutions/solutions.module').then(m => m.SolutionsModule) },
  { path: 'industries', loadChildren: () => import('./industries/expertise.module').then(m => m.ExpertiseModule) },
  { path: 'ourservices', loadChildren: () => import('./our-services/our-services.module').then(m => m.OurServicesModule) },
  // temporary decision (lowercase links at external sites)
  { path: 'solutions/lms', redirectTo: 'solutions/LMS' },
  { path: 'solutions/erp', redirectTo: 'solutions/ERP' },
  { path: 'solutions/cms', redirectTo: 'solutions/CMS' },
  { path: 'technologies/net', redirectTo: 'technologies/NET' },
  { path: 'solutions/crm', redirectTo: 'solutions/CRM' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {
}
