import { Component, Input } from '@angular/core';

@Component({
  selector: 'ba-socials',
  templateUrl: './socials.component.html',
  styleUrls: ['./socials.component.scss']
})
export class SocialsComponent {
  @Input() white: boolean;
  @Input() header: boolean;
}
