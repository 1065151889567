import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IExportResult, IGdprResponse, ISendContactResult } from './services.model';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly apiUrl = environment.settings.backend;

  constructor(private _http: HttpClient) {
  }

  public sendContactUsForm(form): Observable<ISendContactResult> {
    return this._http.post<ISendContactResult>(`${this.apiUrl}/api/contact_us`, form);
  }

  public logCookie(): Observable<IGdprResponse> {
    return this._http.post<IGdprResponse>(`${this.apiUrl}/api/gdpr`, {});
  }

  public exportPdf(data): Observable<IExportResult> {
    return this._http.post<IExportResult>(`${this.apiUrl}/api/estimator/result`, data);
  }

  public downloadPdf(href: string): Observable<Blob> {
    return this._http.get(`${this.apiUrl}/api/estimator/export/pdf?result=${href}`, {responseType: 'blob'});
  }

  public sendFullContactUsForm(form: FormData): Observable<ISendContactResult> {
    return this._http.post<ISendContactResult>(`${this.apiUrl}/api/contact_us/full`, form);
  }
}
