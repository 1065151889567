<!-- Header -->
<section class="nav" [ngClass]="{'invert': invert && !extendedOpen, 'transparent': extendedOpen }">
  <div class="nav__row container" [ngClass]="{ 'extended-open': extendedOpen }" >
    <div class="nav__logo-container">
      <a routerLink="" (click)="handleCloseExtended()" aria-label="Bambooagile logo">
        <div class="nav__logo" [ngClass]="{'with-preview': !isMobile && extendedOpen && currentMenuItem.preview }"></div>
      </a>
    </div>

    <nav *ngIf="!isMobile">
      <div
        *ngFor="let item of menuItems; let menuItemIndex = index"
        [ngClass]="{
          'nav__item-container': true,
          'invert-text': invert && !extendedOpen
        }"
      >
        <a
          [ngClass]="{
            'nav__item': true,
            'selected': menuItemIndex === selectedMenuItemIndex,
            'invert-text': invert && !extendedOpen
          }"
          [routerLink]="item.path"
          routerLinkActive="active"
          (mouseenter)="onMouseHover(item, menuItemIndex)"
          (mouseleave)="clearOpenExtendTimeout()"
          (click)="handleCloseExtended()"
        >
          {{ item.title }}
        </a>
      </div>
    </nav>
    <button
      *ngIf="isMobile"
      class="nav__menu-btn nav__menu-btn--squeeze"
      (click)="toggleMobileMenu()"
      aria-label="Burger menu"
      [ngClass]="[extendedOpen ? 'is-active' : '']"
    >
      <span class="nav__menu-btn__box">
        <span class="nav__menu-btn__inner"></span>
      </span>
    </button>
  </div>
</section>

<!-- Navigation menu -->
<ng-container *ngIf="extendedOpen">
  <section
    @fadeDown
    class="extended container"
  >
    <div class="extended__wrap">
      <div class="extended__content">
        <div *ngIf="!isMobile" class="extended__preview">
          <img
            *ngIf="currentMenuItem.preview"
            [src]="currentMenuItem.preview.bg"
            alt=""
            class="preview__img"
          />
          <ng-container *ngIf="currentMenuItem.preview">
            <div *ngIf="currentMenuItem.preview.info" class="preview" >
              <span class="preview__header">{{ currentMenuItem.preview.info.header }}</span>
              <h1 class="preview__title">{{ currentMenuItem.preview.info.title }}</h1>
              <p class="preview__description">{{ currentMenuItem.preview.info.description }}</p>
              <a
                href="{{currentMenuItem.preview.link}}"
                class="preview__learn-more"
                *ngIf="currentMenuItem.preview.external; else internalLink"
              >Learn more</a>
              <ng-template #internalLink>
                <a
                  routerLink="{{ currentMenuItem.preview.link }}"
                  (click)="handleCloseExtended()"
                  class="preview__learn-more"
                >Learn more</a>
              </ng-template>
            </div>
          </ng-container>
        </div>
        <div class="extended-menu">

          <!-- Mobile navigation. -->
          <ul *ngIf="isMobile" class="extended-menu__linklist">
            <li class="extended-menu__item">
              <a
                href="/"
                class="extended-menu__link"
                routerLink="/"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
                (click)="handleCloseExtended()"
              >
                <span>Home</span>
              </a>
            </li>
            <li
              class="extended-menu__item"
              *ngFor="let item of menuItems; let menuItemIndex = index"
              [ngClass]="{'is-expanded': item.expanded, 'is-active-url': handleCheckCurrentUrl(item.path)}"
            >
              <div *ngIf="menuItemIndex === menuItems.length - 1" class="extended-actions__social">
                <ba-socials></ba-socials>
              </div>
              <a
                class="extended-menu__link"
                (click)="handleShowLinklist(menuItemIndex)"
              >
                <span>{{item.title}}</span>
              </a>
              <div *ngIf="item.submenu && item.expanded" class="extended-menu__submenu-container" @fadeDown>
                <div
                  *ngFor="let submenu of item.submenu; let submenuIndex = index"
                  class="submenu"
                >
                  <div *ngIf="item.submenu.length > 1" class="submenu__title">
                    {{ submenu.title }}
                  </div>
                  <ul class="submenu__linklist">
                    <li
                      *ngFor="let menuLink of submenu.menuLinks"
                      [ngClass]="{'is-active-url': handleCheckCurrentUrl(menuLink.path)}"
                      class="submenu__links"
                    >
                      <a [routerLink]="[submenu.path, menuLink.path ?? '']" (click)="handleCloseExtended()" *ngIf="menuLink.path ?? ''">
                        {{menuLink.title}}
                      </a>
                      <a href="{{menuLink.externalUrlBlank}}" target="_blank" (click)="handleCloseExtended()" *ngIf="menuLink.externalUrlBlank" rel="nofollow noopener">
                        {{menuLink.title}}
                      </a>
                      <a href="{{menuLink.externalUrl}}" (click)="handleCloseExtended()" *ngIf="menuLink.externalUrl">
                        {{menuLink.title}}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>

          <!-- Desktop navigation. -->
          <div *ngIf="currentMenuItem && !isMobile" class="submenu">
            <div class="submenu__container" *ngFor="let subMenu of currentMenuItem.submenu; let submenuIndex = index">
              <div class="submenu__title">
                {{ subMenu.title }}
              </div>
              <div class="submenu__links">
                <ng-container *ngFor="let menuLink of subMenu.menuLinks">
                  <a
                    *ngIf="menuLink.path"
                    [routerLink]="[currentMenuItem.submenu[submenuIndex].path, menuLink?.path]"
                    [routerLinkActive]="'active'"
                    (click)="handleNavigate([currentMenuItem.submenu[submenuIndex].path, menuLink?.path])"
                  >
                    {{ menuLink?.title }}
                  </a>
                  <a href="{{menuLink.externalUrlBlank}}" target="_blank" (click)="handleCloseExtended()" *ngIf="menuLink.externalUrlBlank" rel="nofollow noopener">
                    {{menuLink?.title}}
                  </a>
                  <a href="{{menuLink.externalUrl}}" (click)="handleCloseExtended()" *ngIf="menuLink.externalUrl">
                    {{menuLink?.title}}
                  </a>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="extended-actions">
            <div class="extended-actions__social">
              <ba-socials></ba-socials>
            </div>
            <button class="extended-actions__closeBtn btn" (click)="handleCloseExtended()">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Overlay. -->
    <div
      class="extended__overlay"
      @fade
      *ngIf="extendedOpen"
      (mouseenter)="handleCloseExtended()"
    ></div>
  </section>
</ng-container>
